import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import Advert from '../../Components/Advert';
import HomeArticles from './HomeArticles';
import HomeProfiles from './HomeProfiles';
import {useState, useEffect} from 'react';
import HomeEvents from './HomeEvents';
import HomeMerch from './HomeMerch';

const VIEW_HOME_URL = '/home';

function HomeContent({PF}) {
    const axiosPrivate = useAxiosPrivate();
    const [profiles, setProfiles] = useState([]);
    const [articles, setArticles] = useState([]);
    const [calEvents, setCalEvents] = useState([]);
    const [merch, setMerch] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_HOME_URL).then((response) => {
        if(isMounted){
            setArticles(response.data.articles);
            setProfiles(response.data.profiles);
            setCalEvents(response.data.calEvents);
            setMerch(response.data.merch);
        }
      }).catch((error) => {
        //Handle Errors Coming out of this
      });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
    }, []);
    
    return (
        <div className="col-lg-8 mb-3">
            <div className="row">
                <HomeArticles PF={PF} articles={articles}/>
                <Advert section='1' PF={PF} />
                <HomeProfiles PF={PF} profiles={profiles}/>
                <HomeEvents PF={PF} calEvents={calEvents} />
                <Advert section='1' PF={PF} />
                <HomeMerch PF={PF} merch={merch}/>
            </div>
        </div>
    )
}

export default HomeContent
