import RightSideBar from '../../Components/RightSideBar';
import ProfilesContent from './ProfilesContent';
import TopProfiles from './TopProfiles';

function Profiles({PF}) {    
    return (
        <div className='container-fluid pt-5 mb-3'>
            <TopProfiles PF={PF} />
            <div className="container">
                <div className='row'>
                    <ProfilesContent PF={PF} />
                    <RightSideBar PF={PF} />
                </div>             
            </div>
        </div>
    )
}

export default Profiles