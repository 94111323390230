import {Routes, Route} from 'react-router-dom';
import CommsTrustees from './CommsTrustees';
import CommsAddOBUC from './CommsAddOBUC';
import CommsAddOBC from './CommsAddOBC';
import CommsOBUC from './CommsOBUC';
import CommsAll from './CommsAll';
import CommsOBC from './CommsOBC';

function Committees({PF}) {
  return (
    <Routes>
      <Route exact path='/trustees/:id' element={<CommsTrustees PF={PF}/>} />
      <Route exact path='/obuc/add' element={<CommsAddOBUC PF={PF}/>} />
      <Route exact path='/obc/add' element={<CommsAddOBC PF={PF}/>} />
      <Route exact path='/obuc/:id' element={<CommsOBUC PF={PF}/>} />
      <Route exact path='/obc/:id' element={<CommsOBC PF={PF}/>} />
      <Route exact path='/' element={<CommsAll PF={PF}/>} />
    </Routes>
  )
}

export default Committees