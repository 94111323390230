import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

const VIEW_TRUSTEES_URL = '/committees/trustees';

function TrusteesContent({PF}) {
  const axiosPrivate = useAxiosPrivate();
  const [trustees, setTrustees] = useState({});
  useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_TRUSTEES_URL).then((response)=>{
          isMounted && setTrustees(response.data);
      }).catch((error) => {
          //Handle Errors Coming out of this
      });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-12 mb-3">
      <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">Board of Trustees</h2>
      <p className="mt-3" style={{whiteSpace: "pre-line"}}>
      The has a Board of Trustees that is tasked with several responsibilities key among which is to provide guidance on matters of running the Club. For this reason former Presidents of the Club at the end of their term become Board Members. This is for purposes of continuity.<br /><br />The Board of Trustees is also tasked with having control and oversight over all Club property and assets with exception of cash which is under the control of the Cub Treasurer. The board is also charged with safeguarding the property and assets of the Club in liason with the Executive Committee and Members.
      </p>
      <div>
        <div className="section-title mb-2">
          <h4 className="m-0 text-uppercase font-weight-bold">Trustees</h4>
        </div>
        <div className="row mb-3">
          {trustees.length>0 && trustees.map((item)=>{
            return(
              <div key={item.profileId} className="col-lg-4 mb-4">
              <Link to={`/profiles/view/${item.profileId}`}>
                <div className="position-relative overflow-hidden pr-2" style={{height: "300px"}} >
                    <img className="img-fluid h-100" src={PF + item.profile.pictures} style={{objectFit: "cover"}} alt="" />
                </div>
                <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                  {item.profile.profileTitle}<br />
                  <p className='badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2'>
                    {item.commId===101 ? 'Chairperson' : 'Member'}
                  </p>
                </div>
              </Link>
            </div>
  
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default TrusteesContent