import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

const VIEW_EXCO_URL = '/committees/exco';

function ExcoContent({PF}) {
    const axiosPrivate = useAxiosPrivate();
    const [excom, setExcom] = useState({});
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_EXCO_URL).then((response)=>{
            if(isMounted){
                setExcom(response.data);     
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="col-lg-12 mb-3">
            <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">Executive Committee</h2>
            <p className="mt-3" style={{whiteSpace: "pre-line"}}>
                The management of the Club's day-to-day affairs is delegated to an Executive Committee consisting of not more than 15 members. 
                These are usually elected after every two years at the Annual General Meeting. 
                Members of the Executive Committee are fully subscribed members when they are voted into office and remain so for their entirerity of their term in office.
            </p>
            <div className='row mb-3'>
                <div className="col-lg-5">
                    <div className="section-title mb-1">
                        <h5 className="m-0 text-uppercase font-weight-bold">President</h5>
                    </div>
                    <Link to={`/profiles/view/${excom?.pres?.profileId}`}>
                        <div className="position-relative overflow-hidden mb-2" style={{height: "300px"}} >
                            <img className="img-fluid h-100" src={PF + excom?.pres?.profile.pictures} style={{objectFit: "cover"}} alt="" />
                        </div>
                        <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                            {excom?.pres?.profile.profileTitle}<br />
                        </div>
                    </Link>
                    <p style={{whiteSpace: "pre-line"}}>
                        The President of the Club is the Chief Executive Officer and vision bearer of the Club. 
                        The President is a respectable person with a good track record and high level of integrity and is responsible for the protection, 
                        maintenance and improvement of the  Club's name and the Budo brand.<br /><br />
                        Among other responsbilities the President convenes and presides over all the Executive Committee and General Assembly meetings. 
                        He/She is spokesperson of the club and represents it at all official meetings.
                    </p>  
                </div>
                <div className="col-lg-7">
                    <div className="section-title mb-1">
                        <h5 className="m-0 text-uppercase font-weight-bold">Vice Presidents</h5>
                    </div>
                    <div className="row">
                        {excom?.vp?.length>0 && excom.vp.map((item) =>{
                            return(
                                <div key={item.profileId} className="col-lg-6">
                                    <Link to={`/profiles/view/${item.profile.id}`}>
                                        <div className="position-relative overflow-hidden mb-2" style={{height: "300px"}} >
                                            <img className="img-fluid h-100" src={PF + item.profile.pictures} style={{objectFit: "cover"}} alt="" />
                                        </div>
                                        <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                                            {item.profile.profileTitle}<br />
                                            <p className='badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2'>{item.comm.description}</p>
                                        </div>
                                    </Link>
                                </div>    
                            );
                        })}
                        <p style={{whiteSpace: "pre-line"}}>
                            The President is deputised by two Vice Presidents, 
                            a lady and a Gentleman who shall be persons of the 
                            same attributes and qualities as set out for the President.
                            Where the President is a GentlemanThe First Vice President 
                            is a Lady and a Gentleman where the President is a Lady and 
                            the First Vice President takes precedence in protocol over the 
                            Second Vice President.<br /><br />
                            The Vice Presidents are responsible for representing the Club at 
                            the request of the President and perform all the functions of the 
                            President in his/her absence.
                        </p>  
                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className="col-lg-5">
                    <div className="section-title mb-1">
                        <h5 className="m-0 text-uppercase font-weight-bold">General Secretary</h5>
                    </div>
                    <Link to={`/profiles/view/${excom?.gs?.profile.id}`}>
                        <div className="position-relative overflow-hidden mb-2" style={{height: "300px", width: '100%'}} >
                            <img className="img-fluid h-100 w-100" src={PF + excom?.gs?.profile.pictures} style={{objectFit: "cover"}} alt="" />
                        </div>
                        <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                            {excom?.gs?.profile.profileTitle}<br />
                        </div>
                    </Link>
                    <p className="mt-3" style={{whiteSpace: "pre-line"}}>
                        The General Secretary is the official spokesperson of the Club and is responsible for maintaining the day-to-day activities of the Club Secretariat, keeps an accurate record of the proceedings of all Club meetings and attends to all correspondence to and from the Club Secretariat.<br /><br />
                        The General Secretary is the the Club's Human Resource Manager and a signatory to the Club's bank accounts. He/She presides over meetings in the absence of the President and the Vice Presidents and performs all other duties that may be asigned by the President.
                    </p>  
                </div>
                <div className="col-lg-7">
                    <div className="section-title mb-1">
                        <h5 className="m-0 text-uppercase font-weight-bold">Administrators</h5>
                    </div>
                    <div className='row'>
                        {excom?.admin?.length>0 && excom.admin.map((item) =>{
                            return(
                                <div key={item.profileId} className="col-lg-6">
                                    <Link to={`/profiles/view/${item.profile.id}`}>
                                        <div className="position-relative overflow-hidden mb-2" style={{height: "300px"}} >
                                            <img className="img-fluid h-100" src={PF + item.profile.pictures} style={{objectFit: "cover"}} alt="" />
                                        </div>
                                        <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                                            {item.profile.profileTitle}<br />
                                            <p className='badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2'>{item.comm.description}</p>
                                        </div>
                                    </Link>
                                </div>    
                            );
                        })}
                        <p className="mt-3" style={{whiteSpace: "pre-line"}}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum sit perferendis laudantium sint aliquam qui debitis accusantium, rem totam. Obcaecati facere suscipit iusto quibusdam quasi enim recusandae nobis voluptates inventore.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum sit perferendis laudantium sint aliquam qui debitis accusantium, rem totam. Obcaecati facere suscipit iusto quibusdam quasi enim recusandae nobis voluptates inventore.
                        </p> 
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-lg-3">
                    <div className="section-title mb-1">
                        <h5 className="m-0 text-uppercase font-weight-bold">Treasurer</h5>
                    </div>
                    <Link to={`/profiles/view/${excom?.tres?.profile.id}`}>
                        <div className="position-relative overflow-hidden mb-2" style={{height: "300px"}} >
                            <img className="img-fluid h-100" src={PF + excom?.tres?.profile.pictures} style={{objectFit: "cover"}} alt="" />
                        </div>
                        <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                            {excom?.tres?.profile.profileTitle}<br />
                        </div>
                    </Link>
                    <p className="mt-3" style={{whiteSpace: "pre-line"}}>
                        The Treasurer is a person integrity with an excellent track record of financial mamangement experience and business planning skills. The treasurer receives all funds for the use of the Club, is a signatory to the bank accounts and is responsible for authorising all disbersements of the Club's funds in conformity approved budgets. The Treasurer also heads the Finance Committee and maintain proper accounting records and is in charge of fundraising and enriching the Club's revenue base.
                    </p>  
                </div>
                <div className="col-lg-6">
                    <div className="section-title mb-1">
                        <h5 className="m-0 text-uppercase font-weight-bold">Mobilisers</h5>
                    </div>
                    <div className="row">
                        {excom?.mob?.length>0 && excom.mob.map((item) =>{
                            return(
                                <div key={item.profileId} className="col-lg-6">
                                    <Link to={`/profiles/view/${item.profile.id}`}>
                                        <div className="position-relative overflow-hidden mb-2" style={{height: "300px"}} >
                                            <img className="img-fluid h-100" src={PF + item.profile.pictures} style={{objectFit: "cover"}} alt="" />
                                        </div>
                                        <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                                            {item.profile.profileTitle}<br />
                                            <p className='badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2'>{item.comm.description}</p>
                                        </div>
                                    </Link>
                                </div>    
                            );
                        })}                    
                    </div>
                    <p className="mt-3" style={{whiteSpace: "pre-line"}}>
                        The Mobiliser serves as the Club's Public Relations Manager and is a respectable person with the willingness and ability to reach out to Budonians across the globe. 
                        The Mobiliser has high level of committment to the Club and is able to speak articulately about the Club and its members.<br /><br /> 
                        The Mobiliser has a very good knowledge of the Club, its history and that of the schools. 
                        The Mobiliser represents the Club at various functions and performs other duties as assigned by the President.<br /><br />
                        The Assistant Mobiliser deputises the Mobiliser and has the same personal attributes and qualities as the Mobiliser. 
                        The Assistant Mobiliser assists the Mobiliser in carrying out his/her duties and also performs other duties that may be asigned to him/her by the President.
                    </p>
                </div>
                <div className="col-lg-3">
                    <div className="section-title mb-1">
                        <h5 className="m-0 text-uppercase font-weight-bold">President - OBUC</h5>
                    </div>
                    <Link to={`/profiles/view/${excom?.obuc?.profileId}`}>
                        <div className="position-relative overflow-hidden mb-2" style={{height: "300px"}} >
                            <img className="img-fluid h-100" src={PF + excom?.obuc?.profile.pictures} style={{objectFit: "cover"}} alt="" />
                        </div>
                        <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                            {excom?.obuc?.profile.profileTitle}<br />
                        </div>
                    </Link>
                    <p className="mt-3" style={{whiteSpace: "pre-line"}}>
                        The President of the Old Budonians' University Chapter [OBUC] is elected into office by Old Budonians that are attending institutions of higher learning. By virtue of this office therefore the President, OBUC, represents Old Budonians in tertiary institutions on the Executive Committee. He/She is a member of the mobilisation sub-committee and help in the mobilisation of Budonians to fully participate in Club activities and may perform all other duties as assigned to by the President.
                    </p>  
                </div>
            </div>
            <div>
                <div className="section-title mb-1">
                    <h5 className="m-0 text-uppercase font-weight-bold">Representatives</h5>
                </div>
                <p className="mt-3" style={{whiteSpace: "pre-line"}}>
                    The Executive Committee has a total of 8 representatives of different groupings of the Old Budonians. 
                    There are six Decade Representativees representing Old Budonians grouped in decades according to the year of exit of Senior Six. 
                    In addition to the decade representatives, the Junior school has two additional dedicated representatives. 
                    All representatives are members of the Mobilisation Sub-Committee and help in mobilising Old Budonians to fully participate in Club and School activities. 
                    They are also to carry out other duties as may be assigned by the President from time to time
                </p>  
                <div className="row">
                    { excom?.bjs?.length>0 && excom.bjs.map((item) =>{
                        return(
                            <div key={item.profileId} className="col-lg-3">
                                <Link to={`/profiles/view/${item.profileId}`}>
                                    <div className="position-relative overflow-hidden mb-2" style={{height: "300px"}} >
                                        <img className="img-fluid h-100" src={PF + item.profile.pictures} style={{objectFit: "cover"}} alt="" />
                                    </div>
                                    <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                                        {item.profile.profileTitle}<br />
                                        <p className='badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2'>{item.comm.description}</p>
                                    </div>
                                </Link>
                            </div>    
                        );
                    })}
                    { excom?.drep?.length>0 && excom.drep.map((item) =>{
                        return(
                            <div key={item.profileId} className="col-lg-3">
                                <Link to={`/profiles/view/${item.profileId}`}>
                                    <div className="position-relative overflow-hidden mb-2" style={{height: "300px"}} >
                                        <img className="img-fluid h-100" src={PF + item.profile.pictures} style={{objectFit: "cover"}} alt="" />
                                    </div>
                                    <div className="h6 m-0 text-uppercase font-weight-semi-bold">
                                        {item.profile.profileTitle}<br />
                                        <p className='badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2'>{item.comm.description}</p>
                                    </div>
                                </Link>
                            </div>    
                        );
                    })}
                </div>
            </div>
        </div>          
    )
}

export default ExcoContent