import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const PRODUCTS_URL = '/admin/products';

function Products({PF}) {
  const axiosPrivate = useAxiosPrivate();
  const [products, setProducts] = useState([]);
  const [outOfStock, setOutOfStock] = useState([]);
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(PRODUCTS_URL).then((response)=>{
      if(isMounted){
        setProducts(response.data.products);  
        setOutOfStock(response.data.outOfStock);  
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []); 


  return (
    <div className="col-lg-8">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">Products</h4>
        <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-1" to='/admin/products/add'>Add Product</Link>
      </div>
      <div className="bg-white align-items-center mb-1 overflow-hidden border p-2">
        <h4><b>In Stock</b></h4>
      </div>
      <div className="row mb-3">
        { products && products.map((item) => {
          const image = item.images.split(','); 
          return(
            <div className='col-2 position-relative mb-1' key={item.id}>
              <Link className="d-block text-secondary" to={`/admin/products/${item.id}`}>
                <div className='position-relative overflow-hidden' style={{maxHeight: '85px'}}>
                  <img className='img-fluid w-100' src={PF + image[0]} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
                </div>
                {item.title}
              </Link>
            </div>
          )           
        })}
      </div>
      <div className="bg-white align-items-center mb-1 overflow-hidden p-2 border">
        <h4><b>Out of Stock</b></h4>
      </div>
      <div className="row">
        { outOfStock && outOfStock.map((item) => {
          const image = item.images.split(','); 
          return(
            <div className='col-2 position-relative mb-1' key={item.id}>
              <Link className="d-block text-secondary" to={`/admin/products/${item.id}`}>
                <div className='position-relative overflow-hidden' style={{maxHeight: '85px'}}>
                  <img className='img-fluid w-100' src={PF + image[0]} alt='' style={{objectFit: 'cover', cursor: 'pointer'}} />
                </div>
                {item.title}
              </Link>
            </div>
          )           
        })}
      </div>
    </div>
  )
}

export default Products