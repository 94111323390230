import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const VIEW_CATEGORIES_URL = '/shop/categories';

const CategoryNav = ({catId}) => {
  const axiosPrivate = useAxiosPrivate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    
    axiosPrivate.get(VIEW_CATEGORIES_URL).then((response) => {
      isMounted && setCategories(response.data);
      
    }).catch((error) => {
        //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []);

  return (
    <>
        <Link className="badge badge-primary text-uppercase font-weight-semi-bold mt-3 mb-3 p-2 mr-1" to={`/shop`}>Shopfront</Link>
        {categories?.map((item) => (
            item.id===catId  
            ? (<span key={item.id} className="badge text-uppercase font-weight-semi-bold mt-3 mb-3 p-2 mr-1">{item.title}</span>)
            : (<Link key={item.id} className="badge badge-primary text-uppercase font-weight-semi-bold mt-3 mb-3 p-2 mr-1" to={`/shop/products/${item?.id}`}>{item.title}</Link>)
        ))}
    </>
  );
};

export default CategoryNav;
