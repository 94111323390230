import RightSideBar from "../../Components/RightSideBar";
import NewArticleForm from "./NewArticleForm";


function AddArticle({PF}) {
  return (
    <div className="container-fluid mt-5 pt-3">
      <div className="container">
        <div className="row">
          <NewArticleForm />            
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div>
  );
}

export default AddArticle