import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
//import { Link } from 'react-router-dom'

const VIEW_ADVERT_URL = '/advert/';

function Advert({section, PF}){
    const axiosPrivate = useAxiosPrivate();
    const [advert, setAdvert] = useState([]);  

    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_ADVERT_URL + section).then((response)=>{
        isMounted && setAdvert(response.data);
      }).catch((error) => {
        //Handle Errors Coming out of this
      });
      return () => { isMounted = false; controller.abort(); }
      // eslint-disable-next-line
    }, []);

    return(
        advert.length>0 &&
        <>
            { section ==='1' ? 
                <div className="col-lg-12 mb-3">
                    <a target='_blank' rel='noopener noreferrer' href={`https://${advert[0].company.website}`}><img className="img-fluid w-100" src={PF + advert[0].picture} alt=""/></a>
                </div> 
            : 
                <div className="mb-3">
                    <div className="bg-white text-center border border-top-0 p-0">
                    <a target='_blank' rel='noopener noreferrer' href={`https://${advert[0].company.website}`}><img className="img-fluid" src={PF + advert[0].picture} alt="" /></a>
                    </div>
                </div>
            }
        </>
    )

}

export default Advert