import RequireAuth from '../Components/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import ViewGalleries from './Gallery/Galleries';
import ViewGallery from './Gallery/ViewGallery';
import AddPictures from './Gallery/AddPictures';
import EditPicture from './Gallery/EditPicture';
import AddGallery from './Gallery/AddGallery';
import RightSideBar from '../Components/RightSideBar';

function Gallery({PF}) {
  return (
    <div className='container-fluid pt-5 mb-3'>
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/' element={<ViewGalleries PF={PF} />} />
            <Route exact path='/view/:id' element={<ViewGallery PF={PF} />} />
            <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
              <Route exact path='/add' element={<AddGallery PF={PF} />} />
              <Route exact path='/addpics/:id' element={<AddPictures PF={PF} />} />
              <Route exact path='/edit/:id' element={<EditPicture PF={PF} />} />
            </Route>
          </Routes>
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div> 
  )
}

export default Gallery