import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

const ORDERS_URL = '/admin/orders';

function Orders() {
  const axiosPrivate = useAxiosPrivate();

  const [listPaid, setListPaid] = useState([]);  
  const [listDispatched, setListDispatched] = useState([]);  
  const [listOrdered, setListOrdered] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    axiosPrivate.get(ORDERS_URL).then((response)=>{
      if(isMounted){
        setListPaid(response.data.paid);
        setListDispatched(response.data.dispatched);
        setListOrdered(response.data.ordered);
      }

    }).catch((error) => {
      //Handle Errors Coming out of this
    });

    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-8">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">ORDERS</h4>
      </div>
      { listPaid?.length>0 && 
        <>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Paid Undelivered Orders</b>
          </div>
          <div className="row">
            { listPaid?.map((item) => {
              return(
                <div key={item.id} className='col-lg-2'>
                  <Link className="h6 d-block mb-3 text-secondary p-2" to={`/admin/orders/${item.orderCode}`}>
                    {item?.orderCode}
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      }
      { listDispatched?.length>0 && 
        <>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Dispatched Orders</b>
          </div>
          <div className="row">
            { listDispatched?.map((item) => {
              return(
                <div key={item.id} className='col-lg-2'>
                  <Link className="h6 d-block mb-3 text-secondary p-2" to={`/admin/orders/${item.orderCode}`}>
                    {item?.orderCode}
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      }
      {listOrdered?.length>0 && 
        <>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Unpaid Orders</b>
          </div>
          <div className="row">
            { listOrdered?.map((item) => {
              return(
                <div key={item.id} className='col-lg-2'>
                  <Link className="h6 d-block mb-3 text-secondary p-2" to={`/admin/orders/${item.orderCode}`}>
                    {item?.orderCode}
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      }
    </div>
  )
}

export default Orders
