import RightSideBar from "../../Components/RightSideBar";
import NewStoryForm from "./NewStoryForm";

function AddStory({PF}) {
  return (
    <div className="container-fluid mt-5 pt-3">
      <div className="container">
        <div className="row">
          <NewStoryForm />
          <RightSideBar PF={PF}/>
        </div>
      </div>
    </div>
  );
}

export default AddStory