import RequireAuth from '../Components/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import ViewEvents from './Events/ViewEvents';
import EditEvent from './Events/EditEvent';
import AddEvent from './Events/AddEvent';
import RightSideBar from '../Components/RightSideBar';

function Events({PF}) {
  return (
    <div className='container-fluid pt-5 mb-3'>
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/' element={<ViewEvents PF={PF} />} />
            <Route exact path='/add' element={<AddEvent PF={PF} />} />
            <Route exact path='/view/:id' element={<ViewEvents PF={PF} />} />
              <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
                <Route exact path='/edit/:id' element={<EditEvent PF={PF}/>} />
              </Route>
          </Routes>
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div>
  )
}

export default Events