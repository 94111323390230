import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import CategoryNav from "../components/CategoryNav";
import { useParams} from "react-router-dom";
import { useState, useEffect } from "react";
import List from "../components/List";

const VIEW_CATEGORY_URL = '/shop/category/';

const Products = ({PF}) => {
  let { id } = useParams();
  const [category, setCategory] = useState({});
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    axiosPrivate.get(VIEW_CATEGORY_URL + id).then((response) => {
      isMounted && setCategory(response.data);
    }).catch((error) => {
        //Handle Errors Coming out of this
    });

    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);
 
  if(category===null){
    if(id==='new'){
      setCategory({id: 'new', title: 'New Arrivals',description: 'Newly stocked merchandise', image: 'new_category.jpg'})
    }else if(id==='sale'){
      setCategory({id: 'sale', title: 'Items on Sale',description: 'Discounted merchandise on sale', image: 'sale_category.jpg'})
    }
  }

  return (
    <div className="mb-3">
      <CategoryNav catId={id} />      
      <div style={{height: '150px'}} className="mb-3 overflow-hidden">
        <h2 className="mb-3 text-secondary text-capitalize font-weight-bold text-light" style={{zIndex: 99, position: 'absolute', right: '150px', top: '250px', textShadow: '1px 1px 2px #000000'}}>{category?.title}</h2>
        <img className="img-fluid w-100" style={{objectFit: 'cover'}} src={PF + category?.image} alt=""/>
      </div>
      <List PF={PF} catId={id}/>
    </div>
  );
};

export default Products;
