import { Link, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import { useState} from "react";
import Select from 'react-select';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const PRODUCT_URL = '/admin/products/add';
const UPLOAD_FILE_URL = '/upsingle';

const AddProduct = ({PF}) => {
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

  const axiosPrivate = useAxiosPrivate();
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [featured, setFeatured] = useState();
  const [quantity, setQuantity] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();

  const handleAddProduct = async(e) => {
      e.preventDefault();
      const image = [];      
      if(selectedImages){           
        for(let i=0; i<selectedImages.length; i++) {
          const formData = new FormData();
          const filename = Date.now()+ ' - ' + selectedImages[i].file.name;       
          formData.append("name", filename);
          formData.append("file", selectedImages[i].file);

          try{
            await axiosPrivate.post(UPLOAD_FILE_URL, formData);   
          }catch (error) {
            console.log(error);  
          }        
          image.push(filename);
        }
      }
      
      const newProduct = {title, price, salePrice, featured, quantity, description, images: image.toString(), categories, userId: authDecode.user.id};

      console.log(newProduct);
      try {
          axiosPrivate.post(PRODUCT_URL, newProduct).then(()=>{
              alert('Product Added');
              navigate(`/admin/products`);
          });   
      }catch (error) {
          console.log(error);  
      }
  }

  const categoryOptions = [
    { label: "Men", value: 1 },
    { label: "Women", value: 2 },
    { label: "Gifts", value: 3 },
    { label: "Books", value: 4 }
  ];

  function handleSelect(data) {
    console.log(data);
    setCategories(data);
  }

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;    
    const selectedFilesArray = Array.from(selectedFiles);
  
    const imagesArray = selectedFilesArray.map((file) => {
      return ( {"image": URL.createObjectURL(file), "file": file} )
    });
    
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    
    // FOR BUG IN CHROME
    e.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));   
    URL.revokeObjectURL(image.image);
  }

  return (
    <div className="col-lg-8">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">Add Product</h4>
      </div>
      <form>
        <div className="product row">
          <div className="col-lg-5">
            <div className="left">
              <div className="images row">
                {selectedImages.length === 0 &&
                  <label htmlFor="fileInput">
                    <i className="writeIcon fas fa-plus ml-2"></i> Add Pictures
                    <input id="fileInput" type="file" name="files" style={{display:'none'}} onChange={(e) => onSelectFile(e)} multiple accept="image/png, image/jpeg, image/jpg" />
                  </label>
                }
                {selectedImages && selectedImages.map((image) => { 
                  return (
                    <div key={image.image} className="col-4 mb-2 row" style={{height: '85px'}}>
                      <div className="col-11" style={{height: '70px'}}>
                        <img src={image.image} className='img-fluid w-100 h-100' alt="upload" style={{objectFit: 'cover'}} /><br />
                      </div>
                      <div className="col-1">
                        <small><i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deleteHandler(image)}/></small>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <hr />
            <div className="form-group">
                Featured: &nbsp;<input className='p-2' type="checkbox" id="featured" name="featured" checked={featured} onChange={()=>setFeatured(!featured)} />
            </div>
            <div className="row">
              <div className='col-4 p-2'>Category: </div>
              <div className='col-8'>
              <Select options={categoryOptions} placeholder="Select Category" value={categories} onChange={handleSelect} isMulti />
              </div>
            </div>
            <hr />
            <div>
              <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" onClick={handleAddProduct}>Submit</button>
              &nbsp;&nbsp;&nbsp;  
              <Link className="btn btn-primary font-weight-semi-bold px-4" to='/admin/products'>Cancel</Link>

            </div>
          </div>
          <div className="col-lg-7 align-items-center bg-white mb-1 overflow-hidden p-2 border">
            <div className="form-group">
              Name: <input className="form-control p-2" required="required" type='text' placeholder='Product Name' onChange={(e)=>setTitle(e.target.value)} />
            </div>
            <div className="form-group">
              Price: <input className="form-control p-2" required="required" type='text' placeholder='Price' onChange={(e)=>setPrice(e.target.value)} />
            </div>
            <div className="form-group">
              Sale Price: <input className="form-control p-2" required="required" type='text' placeholder='Sale Price' onChange={(e)=>setSalePrice(e.target.value)} />
            </div>
            <div className="form-group">
              Quantity: <input className="form-control p-2" required="required" type='text' placeholder='Quantity' onChange={(e)=>setQuantity(e.target.value)} />
            </div>

            <div className="form-group">
              Description:
              <textarea placeholder='Product Description' type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
