import RightSideBar from '../../Components/RightSideBar';
import LatestArticlesGrid from './LatestArticlesGrid';
import ArticlesContent from './ArticlesContent';

function ViewArticles({PF}) {
    return (
        <div className='container-fluid pt-5 mb-3'>
            <LatestArticlesGrid PF={PF} />
            <div className="container">
                <div className='row'>
                    <ArticlesContent PF={PF} />
                    <RightSideBar PF={PF} />
                </div>
            </div>
        </div>
    )
}

export default ViewArticles