import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import {HashRouter} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import React from 'react';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <PersistGate loading={"loading"} persistor={persistor}></PersistGate>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>
);

