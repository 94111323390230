import { Link } from 'react-router-dom'

function LeftSideBar() {
  return (
    <div className="col-lg-3">
      <div className="section-title mb-3">
        <h4 className="m-0 text-uppercase font-weight-bold">Admin Menu</h4>
      </div>
      <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
        <i className="fa fa-tachometer" aria-hidden="true" />&nbsp;&nbsp;&nbsp;<Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/admin`}>Dashboard</Link>
      </div>
      <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
        <i className="fa fa-users" aria-hidden="true" />&nbsp;&nbsp;&nbsp;<Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/admin/users`}>Users</Link>
      </div>
      <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
        <i className="fa fa-shopping-cart" aria-hidden="true" />&nbsp;&nbsp;&nbsp;<Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/admin/orders`}>Orders</Link>
      </div>
      <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
        <i className="fa fa-shopping-basket" aria-hidden="true" />&nbsp;&nbsp;&nbsp;<Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/admin/products`}>Products</Link>
      </div>
      <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
        <i className="fa fa-user-plus" aria-hidden="true"/>&nbsp;&nbsp;&nbsp;<Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/admin/committees`}>Committees</Link>
      </div>
      <div className="d-flex align-items-center bg-white mb-1 overflow-hidden p-3 border">
        <i className="fa fa-bell" aria-hidden="true"/>&nbsp;&nbsp;&nbsp;<Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/admin/alerts`}>Alerts</Link>
      </div>
    </div>
  )
}

export default LeftSideBar