import RequireAuth from '../Components/RequireAuth';
import {Routes, Route} from 'react-router-dom';
import ViewArticles from './Articles/Articles';
import AddArticle from './Articles/AddArticle';
import Article from './Articles/Article';

function Articles({PF}) {
  return (
    <Routes>
      <Route exact path='/' element={<ViewArticles PF={PF} />} />
      <Route exact path='/view/:id' element={<Article PF={PF} />} />       
        <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
          <Route exact path='/add' element={<AddArticle PF={PF} />} />
        </Route>
    </Routes>
  )
}

export default Articles