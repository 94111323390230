import RequireAuth from '../Components/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import ViewProfiles from './Profiles/Profiles';
import AddProfile from './Profiles/AddProfile';
import ViewProfile from './Profiles/Profile';

function Profiles({PF}) {
  return (
    <Routes>
      <Route exact path='/' element={<ViewProfiles PF={PF} />} />
      <Route exact path='/view/:id' element={<ViewProfile PF={PF} />} />
      <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
        <Route exact path='/add' element={<AddProfile PF={PF}/>} />
      </Route>
    </Routes>
  )
}

export default Profiles