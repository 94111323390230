import RightSideBar from '../../Components/RightSideBar';
import SingleContent from './SingleContent';

function Page({PF}) {
    return (
        <div className="container-fluid pt-5">
            <div className="container">
                <div className="row">
                    <SingleContent PF={PF} />
                    <RightSideBar PF={PF} />  
                </div>
            </div>
        </div>
    )
}

export default Page