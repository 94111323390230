import { Link, useParams, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import Select from 'react-select';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const PRODUCT_URL = '/admin/products/';
const UPLOAD_FILE_URL = '/upsingle';

const Product = ({PF}) => {
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

  const axiosPrivate = useAxiosPrivate();
  const [selectedImg, setSelectedImg] = useState('');
  const [updateMode, setUpdateMode] = useState(false);
  //const [file, setFile] = useState(null);
  const [product, setProduct] = useState({});
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [featured, setFeatured] = useState();
  const [quantity, setQuantity] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();
  let {id} = useParams();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    
    axiosPrivate.get(PRODUCT_URL + id).then((response) => {
      if(isMounted){ 
        setProduct(response.data.merch);
        setTitle(response.data.merch.title);
        setPrice(response.data.merch.price);
        setSalePrice(response.data.merch.salePrice);
        setFeatured(response.data.merch.featured);
        setQuantity(response.data.merch.quantity);
        setDescription(response.data.merch.description);
        setImage(response.data.merch.images.split(','));
        setCategories(response.data.merchCats);
      }
    }).catch((error) => {
        //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  const archiveProduct = async(id) => {
    try {
      axiosPrivate.put(PRODUCT_URL + id).then(()=>{
        alert('Product Archived');
        navigate('/admin/products');
      });               
    } catch (error) {    }
  };

  const deleteImage = async(index) => {
    image.splice(index, 1);
  };

  const updateProduct = async(e) => {
      e.preventDefault();
      
      if(selectedImages){           
        for(let i=0; i<selectedImages.length; i++) {
          const formData = new FormData();
          const filename = Date.now()+ ' - ' + selectedImages[i].file.name;       
          formData.append("name", filename);
          formData.append("file", selectedImages[i].file);

          try{
            await axiosPrivate.post(UPLOAD_FILE_URL, formData);   
          }catch (error) {
            console.log(error);  
          }        
          image.push(filename);
        }
      }
      
      const updatedProduct = {title, price, salePrice, featured, quantity, description, images: image.toString(), categories, userId: authDecode.user.id};

      try {
          axiosPrivate.put(PRODUCT_URL + product.id, updatedProduct).then(()=>{
              alert('Product Edited');
              setUpdateMode(false);
              navigate(`/admin/products/${product.id}`);
          });   
      }catch (error) {
          console.log(error);  
      }
  }

  const categoryOptions = [
    { label: "Men", value: 1 },
    { label: "Women", value: 2 },
    { label: "Gifts", value: 3 },
    { label: "Books", value: 4 }
  ];

  function handleSelect(data) {
    setCategories(data);
  }

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;    
    const selectedFilesArray = Array.from(selectedFiles);
  
    const imagesArray = selectedFilesArray.map((file) => {
      return ( {"image": URL.createObjectURL(file), "file": file} )
    });
    
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    
    // FOR BUG IN CHROME
    e.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));   
    URL.revokeObjectURL(image.image);
  }

  return (
    <div className="col-lg-8">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">{title}</h4>
        <div>
          <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-1" to='' onClick={() => setUpdateMode(true)}>Edit</Link>
          <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-1" to='' onClick={archiveProduct}>Archive</Link>
        </div>
      </div>
      {updateMode ? 
        <>
          <form>
            <div className="product row">
              <div className="col-lg-5">
                <div className="left">
                  <div className="images row">
                    { image?.map((img, index)=>(
                      <div key={index} className="col-4 mb-2 row" style={{height: '85px'}}>
                        <div className="col-11" style={{height: '70px'}}>
                          <img className='img-fluid w-100 h-100' src={ PF + img } alt="" style={{objectFit: 'cover'}}/>
                        </div>
                        <div className="col-1">
                          <small><i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={(e) => deleteImage(index)} style={{cursor: 'pointer'}} /></small>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="images row">
                    {selectedImages.length === 0 &&
                      <label htmlFor="fileInput">
                        <i className="writeIcon fas fa-plus ml-2"></i> Add Pictures
                        <input id="fileInput" type="file" name="files" style={{display:'none'}} onChange={(e) => onSelectFile(e)} multiple accept="image/png, image/jpeg, image/jpg" />
                      </label>
                    }
                    {selectedImages && selectedImages.map((image) => { 
                      return (
                        <div key={image.image} className="col-4 mb-2 row" style={{height: '85px'}}>
                          <div className="col-11" style={{height: '70px'}}>
                            <img src={image.image} className='img-fluid w-100 h-100' alt="upload" style={{objectFit: 'cover'}} /><br />
                          </div>
                          <div className="col-1">
                            <small><i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deleteHandler(image)}/></small>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <hr />
                <div className="form-group">
                    Featured: &nbsp;<input className='p-2' type="checkbox" id="featured" name="featured" checked={featured} onChange={()=>setFeatured(!featured)} />
                </div>
                <div className="row">
                  <div className='col-4 p-2'>Category: </div>
                  <div className='col-8'>
                  <Select options={categoryOptions} placeholder="Select Category" value={categories} onChange={handleSelect} isMulti />
                  </div>
                </div>
                <hr />
                <div>
                  <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" onClick={updateProduct}>Update</button>
                  &nbsp;&nbsp;&nbsp;  
                  <button className="btn btn-primary font-weight-semi-bold px-4" onClick={(e)=>setUpdateMode(false)}>Cancel</button>
                </div>
              </div>
              <div className="col-lg-7 align-items-center bg-white mb-1 overflow-hidden p-2 border">
                <div className="form-group">
                  Name: <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
                </div>
                <div className="form-group">
                  Price: <input className="form-control p-2" required="required" type='text' value={price} onChange={(e)=>setPrice(e.target.value)} />
                </div>
                <div className="form-group">
                  Sale Price: <input className="form-control p-2" required="required" type='text' value={salePrice} onChange={(e)=>setSalePrice(e.target.value)} />
                </div>
                <div className="form-group">
                  Quantity: <input className="form-control p-2" required="required" type='text' value={quantity} onChange={(e)=>setQuantity(e.target.value)} />
                </div>

                <div className="form-group">
                  Description:
                  <textarea value={description} type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
                </div>
              </div>
            </div>
          </form>
        </> 
        :
        <>
          <div className="product row">
            <div className="col-lg-2">
              <div className="left">
                <div className="images row">
                  { image?.map((img, index)=>(
                    <div key={index} className="col-6 mb-2" style={{height: '70px'}}>
                      <img className='img-fluid w-100 h-100' src={ PF + img } alt="" onClick={(e) => setSelectedImg(index)}  style={{objectFit: 'cover', cursor: 'pointer'}}/>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="left">         
                <div className="mainImg">
                  <img src={ image[selectedImg] ? PF + image[selectedImg] : PF + image[0] } alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 align-items-center bg-white mb-1 overflow-hidden p-2 border">
              <b>Price: </b>{price && price.toLocaleString('en')}<br />
              <b>Sale Price: </b>{salePrice>0 ? salePrice.toLocaleString('en') : 'Not on Sale'}<br />
              <b>Featured: </b>{featured===true ? 'Yes' : 'No'}<br />
              <b>Quantity: </b>{quantity}<br />
              <b>Orders: </b>{product?.orders}<br />
              <b>Views: </b>{product?.views}<br /><br />
              <b>Description</b><br />{description}
              <hr />
              <b>Categories</b><br />
              {categories.length>0 && categories.map((item) => {
                if(item.value===1){
                  return(<span key={item.value}> - Men<br/></span>);
                }else if(item.value===2){
                  return(<span key={item.value}> - Women<br/></span>);
                }else if(item.value===3){
                  return(<span key={item.value}> - Gifts<br/></span>);
                }
                  return(<span key={item.value}> - Books<br/></span>);
              })}
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Product;
