import { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import axios from '../Assets/axios';

const REGISTER_URL = '/users/register';
const USER_REGEX =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register = () => {
    const userRef = useRef();
    const errRef = useRef();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(USER_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [email, pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(email);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }

        try {
            await axios.post(REGISTER_URL, 
                JSON.stringify({email, pwd}),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setEmail('');
            setPwd('');
            setMatchPwd('');
            navigate('/login');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('User Already Exists');
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    return (
        <div className='container-fluid pt-5 mb-3'>
            <div className="container">
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className="section-title mb-3">
                        <h4 className="m-0 text-uppercase font-weight-bold">Register</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            {/** Username **/}
                            <label htmlFor="username">
                                Email:
                                <i className={validEmail ? "valid fa-solid fa-circle-check" : "hide fa-solid fa-circle-check"} />
                                <i className={validEmail || !email ? "hide far fa-times" : "invalid far fa-times"} />
                            </label>
                            <br />
                            <input type="text" id="username" ref={userRef} autoComplete="off" onChange={(e) => setEmail(e.target.value)} value={email} required aria-invalid={validEmail ? "false" : "true"} aria-describedby="uidnote" onFocus={() => setEmailFocus(true)} onBlur={() => setEmailFocus(false)} />
                            <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                                <i className='far fa-info-circle' />
                                4 to 24 characters.<br />
                                Must begin with a letter.<br />
                                Letters, numbers, underscores, hyphens allowed.
                            </p>
                        </div>
                        <div className="form-group">
                            {/** Password **/}
                            <label htmlFor="password">
                                Password:
                                <i className={validPwd ? "valid fa-solid fa-circle-check" : "hide fa-solid fa-circle-check"} />
                                <i className={validPwd || !pwd ? "hide singleIcon far fa-times" : "invalid singleIcon far fa-times"} />
                            </label>
                            <br />
                            <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required aria-invalid={validPwd ? "false" : "true"} aria-describedby="pwdnote" onFocus={() => setPwdFocus(true)} onBlur={() => setPwdFocus(false)} />
                            <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                <i className='far singleIcon fa-info-circle' />
                                8 to 24 characters.<br />
                                Must include uppercase and lowercase letters, a number and a special character.<br />
                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                            </p>
                        </div>
                        <div className="form-group">
                            {/**Confirm Password **/}
                            <label htmlFor="confirm_pwd">
                                Confirm Password:
                                <i className={validMatch && matchPwd ? "valid fa-solid fa-circle-check" : "hide fa-solid fa-circle-check"} />
                                <i className={validMatch || !matchPwd ? "hide fa-solid fa-circlexmark" : "invalid fa-solid fa-circlexmark"} />
                            </label>
                            <br />
                            <input type="password" id="confirm_pwd" onChange={(e) => setMatchPwd(e.target.value)} value={matchPwd} required aria-invalid={validMatch ? "false" : "true"} aria-describedby="confirmnote" onFocus={() => setMatchFocus(true)} onBlur={() => setMatchFocus(false)} />
                            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                <i className='singleIcon far fa-info-circle' />
                                Must match the first password input field.
                            </p>
                        </div>
                        <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '50px'}} disabled={!validEmail || !validPwd || !validMatch ? true : false}>Sign Up</button>
                    </form>
                    <p>
                        Already registered?
                        <span className="line">
                            {/*put router link here*/}
                            <Link className="badge badge-primary text-uppercase font-weight-semi-bold mt-3 mb-3 p-2 ml-2" to="/login">Sign In</Link>
                        </span>
                    </p>
                </section>
            </div>
        </div>
    )
}

export default Register