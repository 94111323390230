import { Link } from "react-router-dom"

const ComingSoon = ({img}) => {
  return (
    <>

        <div className='container-fluid pt-5 mb-3'>
            <div className="container">
                <div className="section-title mb-3">
                    <h4 className="m-0 text-uppercase font-weight-bold">Coming Soon</h4>
                </div> { /* <!-- section title --> */ }
                <div className="row">
                    <div className="col-lg-6">
                        <div className="happy-student mt-30 mb-30">
                            <p>This page is still under construction and the innformation will be available soon.</p><br />
                            <span className="align-items-center"><Link to={'/'} className="main-btn mb-4">Return Home</Link></span>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className='mt-30'>
                            <div className='pt-45'>
                                <img src={`img/soon.jpg`} alt="Coming Soon" className="w-100"/>
                            </div>
                        </div>
                    </div>
                </div> { /*<!-- row --> */}
            </div> { /*<!-- container --> */}
        </div>
    </>

  )
}

export default ComingSoon

