import FeaturedProducts from '../components/FeaturedProducts';
import Categories from '../components/Categories';

const Home = ({PF}) => {
  return (
    <div className='home container-fluid container pt-3'>
      <Categories PF={PF}/>
      <FeaturedProducts PF={PF} type="featured"/>
      <FeaturedProducts PF={PF} type="trending"/>
    </div>
  )
}

export default Home