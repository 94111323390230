import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const ALERTS_URL = '/admin/alerts';

function Alerts ({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [alerts, setAlerts] = useState([]);
    const [expired, setExpired] = useState([]);
    const [addAlert, setAddAlert] = useState(false);
    const [editAlert, setEditAlert] = useState(false);
    const [alertDetail, setAlertDetail] = useState('');
    const [alertExpiry, setAlertExpiry] = useState('');
    const [alertNum, setAlertNum] = useState('');
    
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(ALERTS_URL).then((response)=>{
        if(isMounted){
          setAlerts(response.data.alerts);  
          setExpired(response.data.expired);            
        }
      }).catch((error) => {
        //Handle Errors Coming out of this
      });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
    }, []); 

    const handleAddAlert = async (e) => {
        e.preventDefault();
        const newAlert = {detail: alertDetail, expiry: alertExpiry, archive: 0, userId: authDecode.user.id};
          
        try {
          await axiosPrivate.post(ALERTS_URL + '/add', newAlert).then((response)=>{
            alert('New Alert Added')
            setAddAlert(false);
            window.location.reload();
          });   
        }catch (error) {
          console.log(error);  
        }
    };

    const handleEditAlert = (index, detail, expiry) =>{
        setAlertNum(index)
        setEditAlert(true)
        setAlertDetail(detail)
        setAlertExpiry(expiry)
    }

    const handleUpdateAlert = async(e, id) => {
        e.preventDefault();
        const updatedShelf = {detail: alertDetail, expiry: alertExpiry, userId: authDecode.user.id, id: id};
        try {
          axiosPrivate.put(ALERTS_URL + '/' + id, updatedShelf).then(()=>{
            setEditAlert(false);
            alert('Alert Edited');
            window.location.reload();
          });   
        }catch (error) {
            console.log(error);  
        }
    }

    const handleDeleteAlert = async(id) => {
        try {
        axiosPrivate.delete(ALERTS_URL + '/'+ id).then(()=>{
            alert('Alert Deleted');
            window.location.reload();
        });               
        } catch (error) {       
        }
    }
    
    return (
        <div className="col-lg-8">
        <div className="section-title">
          <h4 className="m-0 text-uppercase font-weight-bold">Alerts</h4>
          <span className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-1" onClick={()=>setAddAlert(true)}>Add Alert</span>
        </div>
        {addAlert && 
          <form action="" onSubmit={handleAddAlert} className='mb-5'>
            <div className="row">
            <div className="form-group col-lg-8">
              <input type="text" placeholder="Alert Detail" className="form-control p-4" autoFocus={true} onChange={(e)=>setAlertDetail(e.target.value)} />
            </div>
            <div className="form-group col-lg-4">
                <input type='date' className="p-4 form-control" autoFocus={true} onChange={(e)=>setAlertExpiry(e.target.value)} />
            </div>
            </div>
            <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!alertDetail || !alertExpiry} type="submit">Submit</button>
            &nbsp;&nbsp;&nbsp;  
            <button className="btn btn-primary font-weight-semi-bold px-4" onClick={()=>setAddAlert(false)}>Cancel</button>  
          </form>
        }
        <div className="bg-white align-items-center mb-1 overflow-hidden border p-2">
          <h4><b>Active</b></h4>
        </div>
        <div className="mb-1 p-2">
            { alerts.length>0 ? alerts.map((item, index) => {
                return(
                    <div className='mb-1' key={item.id}>
                        { (editAlert && alertNum===index) ? 
                            <form action="" className='mb-5'>
                                <div className='row'>
                                <div className="col-lg-9 form-group">
                                    <input type="text"value={alertDetail} className="form-control p-4" autoFocus={true} onChange={(e)=>setAlertDetail(e.target.value)} />
                                </div>
                                <div className="col-lg-3 form-group">
                                    <input value={alertExpiry} type='date' className="p-4 form-control" autoFocus={true} onChange={(e)=>setAlertExpiry(e.target.value)} />
                                </div>
                                </div>
                                <button className="btn btn-primary font-weight-semi-bold px-4" disabled={!alertDetail || !alertExpiry} type="submit" onClick={(e)=>handleUpdateAlert(e, item.id)}>Submit</button>
                                &nbsp;&nbsp;&nbsp;  
                                <button className="btn btn-primary font-weight-semi-bold px-4" onClick={()=>setEditAlert(false)}>Cancel</button>  
                            </form>
                        :
                            <div className='row'>
                                <div className='position-relative col-lg-8 bg-white p-2'>
                                    <p className="d-block text-secondary">
                                        {item.detail}
                                    </p>
                                </div>
                                <div className='position-relative col-lg-3 bg-white p-2'>
                                    <p className="d-block text-secondary">
                                        Expires <b>{item.expiry}</b>
                                    </p>
                                </div>
                                <div className='col-lg-1 align-items-right bg-white p-2'>
                                    {authDecode && <>
                                    <i className='singleIcon far fa-edit mr-2' onClick={()=>handleEditAlert(index, item.detail, item.expiry)}/>
                                    <i className='singleIcon far fa-trash-alt' onClick={()=>handleDeleteAlert(item.id)} />
                                    </>}
                                </div>
                            </div>
                        }
                    </div>
                )           
            })
            : 
                <div className='position-relative mt-2 mb-4' >
                    <p className="d-block text-secondary">No Active Alerts</p>
                </div>
            }
        </div>
        { expired.length>0 &&
            <> 
                <div className="bg-white align-items-center mb-1 overflow-hidden p-2 border">
                <h4><b>Expired Alerts</b></h4>
                </div>
                <div className="row">
                { expired.length>0 && expired.map((item, index) => {
                        return(
                        <div className='position-relative mt-2 mb-4' key={item.id}>
                            <p className="d-block text-secondary">
                                {index+1 + '. '+ item.detail} <b>- Runs until {item.expiry}</b>
                            </p>
                        </div>
                        )           
                    })}
                </div>
            </>
        }
      </div>
    )
}

export default Alerts
