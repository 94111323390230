import LeftSideBar from '../Components/LeftSideBar';
import ProductDetails from './Admin/ProductDetails'
import OrderDetails from './Admin/OrderDetails';
import {Routes, Route} from 'react-router-dom';
import UserDetails from './Admin/UserDetails';
import Committees from './Admin/Committees'
import ProductAdd from './Admin/ProductAdd'
import Dashboard from './Admin/Dashboard';
import Products from './Admin/Products';
import Orders from './Admin/Orders';
import Alerts from './Admin/Alerts';
import Users from './Admin/Users';

function Admin({PF}) {
  return (
    <div className="container-fluid pt-5 mb-3">
      <div className="container">
        <div className='row'>
          <LeftSideBar PF={PF} />
          <Routes>
            <Route exact path='/products/:id' element={<ProductDetails PF={PF}/>} />
            <Route exact path='/products/add' element={<ProductAdd PF={PF}/>} />
            <Route exact path='/committees/*' element={<Committees PF={PF}/>} />
            <Route exact path='/orders/:id' element={<OrderDetails PF={PF}/>} />
            <Route exact path='/users/:id' element={<UserDetails PF={PF}/>} />
            <Route exact path='/products' element={<Products PF={PF}/>} />
            <Route exact path='/orders' element={<Orders PF={PF}/>} />
            <Route exact path='/alerts' element={<Alerts PF={PF}/>} />
            <Route exact path='/users' element={<Users PF={PF}/>} />
            <Route exact path='/' element={<Dashboard PF={PF}/>} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Admin