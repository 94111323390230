import {Routes, Route} from 'react-router-dom';
import ViewUser from './User/ViewUser';
import ViewPosts from './User/ViewPosts';
import ViewAlum from './User/ViewAlum';
import RightSideBar from '../Components/RightSideBar';

function User({PF}) {
  return (
    <div className="container-fluid pt-5 mb-3">
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/posts/:id' element={<ViewPosts PF={PF} />} />
            <Route exact path='/alum/:id' element={<ViewAlum PF={PF}/>} />
            <Route exact path='/view/:id' element={<ViewUser PF={PF} />} />
          </Routes>
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div>
  )
}

export default User