import { Link } from "react-router-dom";
import './Components.scss';

const Categories = ({PF}) => {
  return (
    <div className="categories col-lg-12">
      <div className="col col-lg-4">
        <div className="row">
          <img src={PF + 'sale_category.jpg'} alt="" />
          <button><Link className="link" to="/shop/products/sale"> Sale </Link></button>
        </div>
        <div className="row">
          <img src={PF + 'books_category.jpg'} alt="" />
          <button><Link to="/shop/products/4" className="link"> Books </Link></button>
        </div>
      </div>
      <div className="col col-lg-4">
        <div className="row">
          <img src={PF + 'womens_category.jpg'} alt="" />
          <button><Link to="/shop/products/2" className="link"> Women </Link></button>
        </div>
        <div className="row">
          <img src={PF + 'new_category.jpg'} alt="" />
          <button><Link to="/shop/products/new" className="link"> New  </Link></button>
        </div>
      </div>
      <div className="col col-lg-4">
        <div className="row">
          <img src={PF + 'mens_category.jpg'} alt=""  />
          <button><Link to="/shop/products/1" className="link">Men</Link></button>
        </div>
        <div className="row">
          <img src={PF + 'gifts_category.jpg'} alt="" />
          <button> <Link to="/shop/products/3" className="link"> Gifts </Link> </button>
        </div>
      </div>
    </div>
  );
};

export default Categories;
