import { Link } from 'react-router-dom';

function HomeEvents({PF, calEvents}) {
    return (
        <>
            { calEvents.length>0 &&
                <>
                    <div className="col-12">
                        <div className="section-title">
                            <h4 className="m-0 text-uppercase font-weight-bold">Upcoming Events</h4>
                            <Link className="text-secondary font-weight-medium text-decoration-none" to="/events">View All</Link>
                        </div>
                    </div>
                    {calEvents.map((calEvent) => {
                        return(
                            <div key={calEvent.id} className="col-lg-6">
                                <div className="w-100 position-relative overflow-hidden d-flex bg-white mb-3 border border-left-0" style={{height: '110px', width: '110px'}} >
                                    <img className="img-fluid h-100" src={PF + calEvent.picture} alt="" object-fit='cover'/>
                                    <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center">
                                        <div className="mb-2">
                                            <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to="/">{calEvent.location}</Link><br />
                                            <small>{new Date(calEvent.date).toDateString()}</small>
                                        </div>
                                        <Link className="h6 m-0 text-secondary text-uppercase font-weight-bold" to={`/events`}>{calEvent.title}</Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}                  
                </>
            }
        </>
    )
}

export default HomeEvents
