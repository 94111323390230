import Confirmation from './Shop/pages/Confirmation';
import RequireAuth from '../Components/RequireAuth';
import {Routes, Route} from 'react-router-dom';
import ViewCart from './Shop/pages/ViewCart';
import Products from './Shop/pages/Products';
import Product from './Shop/pages/Product';
import Payment from './Shop/pages/Payment';
import './Shop/components/Components.scss';
import Home from './Shop/pages/Home';
import './Shop/pages/Pages.scss';

const Shop = ({PF}) => {
  return (
    <div className="container container-fluid mb-3">
      <Routes>
        <Route exact path='/' element={<Home PF={PF} />} />
        <Route exact path='/products/:id' element={<Products PF={PF}/>} />
        <Route exact path='/product/:id' element={<Product PF={PF} />} />
        <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4, 5]} />}>
          <Route exact path='/payment/:id' element={<Payment PF={PF} />} />
          <Route exact path='/confirmation/:id' element={<Confirmation PF={PF} />} />
          <Route exact path='/cart' element={<ViewCart PF={PF} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default Shop
