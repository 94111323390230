import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from 'react-share'

function SocialShare({currentPage, title}) {

    return (
        <div className="singleEdit">
            Share: 
            <WhatsappShareButton url={currentPage} title={title}>
                <p className="font-weight-medium">
                    <i className="fab fa-whatsapp ml-2 mr-2" />
                </p>
            </WhatsappShareButton>
            <FacebookShareButton url={currentPage}>
                <p className="font-weight-medium">
                    <i className="fab fa-facebook mr-2" />
                </p>
            </FacebookShareButton>
            <TwitterShareButton url={currentPage} title={title}>
                <p className="font-weight-medium">
                    <i className="fab fa-twitter mr-2" />
                </p>
            </TwitterShareButton>
            <LinkedinShareButton url={currentPage} title={title}>
                <p className="font-weight-medium">
                    <i className="fab fa-linkedin mr-2" />
                </p>
            </LinkedinShareButton>
        </div>
    )
}

export default SocialShare;